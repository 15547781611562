<template>
  <div class="error-wrapper container">
    <div class="imagae-wrap">
      <img src="../assets/payment-success.svg" alt="" />
      <p>Subscription has been successful!</p>
    </div>
    <router-link :to="{ name: 'Dashboard' }" class="btn cta-clear"
      ><i class="fas fa-long-arrow-alt-left"></i>back to dashboard
    </router-link>
  </div>
</template>

<script>
export default {
  name: "PaymentSuccess",
  components: {},
};
</script>

<style lang="scss">
.error-wrapper {
  padding-top: 200px;
  padding-bottom: 100px;
  text-align: center;
  .imagae-wrap {
    padding: 2rem;
    max-width: 600px;
    margin: auto;
  }
  .cta-clear {
    border: 1px solid;
    i {
      padding-right: 15px;
    }
  }
}
</style>
